import request from '../../utils/request'

export default class UserRequest {
  // 获取列表
  async listPost (data) {
    return await request.post('/System/User/Select', data)
  }

  // 添加操作
  async addPost (data) {
    return request.post('/System/User/Insert', data)
  }

  // 编辑操作
  async editPost (data) {
    return request.post('/System/User/Edit', data)
  }

  // 删除操作
  async deletePost (data) {
    return request.post('/System/User/Delete', data)
  }

  // 导出操作
  async exportPost (data) {
    return request.post('/System/User/Export', data)
  }

  // 导入操作
  async importPost (data) {
    return request.post('/System/User/Import', data)
  }
}
